import { default as adRegManagerInit } from './ad-reg.js';
import { default as helpPinInit } from './help-pin.js';
import { loader as popupsLoader, confirm, } from "./popups.js";
import { showMessaging } from './folks-selector.js';
import { default as flightDetailsInit, toggleFlightDetails } from './flight-details.js';
import { breakpoints, scrollTo } from "./utilities.js"


var bookingWidgetFactory = function() {
	// keep track of lazy request initiated by the first tab click
	var hasLazyLoadedWidgets = false;
	var lazyLoadWidgetRequest = null;


	// bind events for a booking widget
	var bindBookingWidget = function(form) {
		var request = null;
		var wrapper = form.closest('.js-booking-widget-wrapper');
		var loader = wrapper.find('.js-booking-loader');

		// carry on
		var offset = wrapper.find('.js-booking-cal-offset');
		var isMultiples = $('#js-multiples-booking').length;

		var mergeFormAndQuery = function(q, f) {
			var formLookup = (f.serialize() || "").split("&");
			var queryLookup = (q || "").split("&");

			var newQuery = formLookup.map(o => {
				var kvp = o.split("=");
				return ({ key: kvp[0], value: kvp[1] });
			}).reduce((obj, current) => {
				obj[current.key] = current.value;
				return obj;
			}, {})

			queryLookup = queryLookup.map(o => {
				var kvp = o.split("=");
				return ({ key: kvp[0], value: kvp[1] });
			});


			for (var kvp of queryLookup) {
				newQuery[kvp.key] = kvp.value;
			}

			return Object.keys(newQuery).map(k => `${k}=${newQuery[k]}`).reduce((str, current) => `${str}&${current}`, "");
		}

		// update
		var _update = function(moveInView, scrollToWidgetMobile, url) {
			// show loader
			if (isMultiples) {
				popupsLoader();
			}
			else {
				loader.show();
			}

			// abort prev request
			if (request != null) {
				request.abort();
				request = null;
			}

			var queryString = url ? url.replace('?', '') : url;
			// new request
			queryString = mergeFormAndQuery(queryString, form);
			console.log(queryString);
			request = sendRequest({
				data: queryString || form.serialize(),
				success: function(response) {
					try {
						renderWidgets(response);
					}
					catch (e) {
						if (console !== 'undefined') {
							console.log('Error: Could not parseJSON for booking widget: booking-widget.js');
						}
						confirm('Error', 'Sorry, there was a problem finding availability. Please try again or call our sales team on ' + response.SupportTelephone + '.', '', true);
					}
				},
				error: function(e) {
					if (e.statusText != 'abort') {
						confirm('Error', 'Sorry, there was an error (' + e.statusText.toLowerCase() + ').', '', true);
					}
				},
				complete: function() {

					// Remove booking widget class so that events will be re-assigned, call init immediately!

					$('.js-booking-widget-done').removeClass('js-booking-widget-done');

					helpPinInit();
					adRegManagerInit();

					if (isMultiples) {

						// scroll on mobile
						if (scrollToWidgetMobile) {
							if (window.matchMedia('(max-width: ' + breakpoints.desktop + 'px)').matches) {
								scrollTo($("#js-main-booking").offset().top);
							}
						}


						//scroll to inview group on desktop
						if (moveInView) {
							if (window.matchMedia('(min-width: ' + breakpoints.desktop + 'px)').matches) {
								if ($(".selected-group").length) {
									scrollTo($(".selected-group").offset().top - 100);
								}
							}
						}

						// loader
						popupsLoader(true);
					}
					else {
						loader.hide();
					}
				}
			});
		};


		// multiples widget moves it into the viewport when we update
		var _isMultiplesWidget = function(element) {
			return element.closest('.js-property-multiples').length;
		}

		// pick a room button
		wrapper.find('.js-booking-pick-room-button').off('click').on('click', function() {
			if (isMultiples) {
				// tab
				$('#takeover-tab').click();
				// scroll
				scrollTo($("#js-multiples-booking").offset().top);
			}
			return false;
		})

		// selects
		wrapper.find('.js-booking-select').off('change').on('change', function() {
			// update and move inview if multiples
			_update(_isMultiplesWidget($(this)));
			return false;
		});

		// cal dates
		wrapper.find('.js-booking-cal-date').off('click').on('click', function() {
			// set radio
			$(this).find('.js-booking-cal-date-input').prop('checked', true);
			// update and move inview if multiples
			_update(_isMultiplesWidget($(this)), true);
			return false;
		});

		// cal prev
		wrapper.find('.js-booking-cal-prev').off('click').on('click', function() {
			// update offset
			offset.val('-1');
			// update and move inview if multiples
			_update(_isMultiplesWidget($(this)), null, $(this).attr('href'));
			return false;
		});

		// cal next
		wrapper.find('.js-booking-cal-next').off('click').on('click', function() {
			// update offset
			offset.val('1');
			// update and move inview if multiples
			_update(_isMultiplesWidget($(this)), null, $(this).attr('href'));
			return false;
		});

		// dropdown cal month
		wrapper.find('.js-booking-cal-month').off('click').on('click', function() {
			// update offset
			offset.val($(this).attr('data-offset'));
			// update and move inview if multiples
			_update(_isMultiplesWidget($(this)), false, $(this).attr('href'));
			return false;
		});

		// main submit
		wrapper.find('.js-booking-submit').off('click').on('click', function() {

			var form = $(this).closest('form');
			var inputElement = form.find('input[name="unitid"]')
			inputElement.prop('disabled', false);

			// show loader
			popupsLoader(false, null, true);
			// set timeout to give IE/Edge time to show gif loader
			setTimeout(function() {
				location.href = '?' + form.serialize() + '&submitButton=true';
			}, 200);
			return false;
		});

		// multiples submit
		wrapper.find('.js-multiples-submit').off('click').on('click', function() {
			// update
			_update(true, true);
			return false;
		});


		// tabs
		wrapper.find('.js-booking-accommodation-only-tab').off('click').on('click', function() {
			// set hidden input
			wrapper.find('.js-booking-accommodation-only-input').attr('value', 'true');
			// update
			_update();
			return false;
		});

		wrapper.find('.js-booking-package-tab').off('click').on('click', function() {
			// set hidden input
			wrapper.find('.js-booking-accommodation-only-input').prop('value', 'false');
			wrapper.find('#js-booking-widget-recover-from-session').attr('value', 'true');
			// update
			_update();
			return false;
		});

		// generic submit (currently only triggered by folks selector and hidden from user interface)
		wrapper.find('.js-booking-widget-generic-submit').off('click').on('click', function() {
			// update and move inview if multiples
			_update(_isMultiplesWidget($(this)));
			return false;
		});

		import("/js/toggle-block.js")
			.then((module) => {
				module.default();
			})
			.catch((err) => console.log(err.message));


		// done
		form.addClass('js-booking-widget-done');
	}

	// load group widgets for the first time
	var onFirstLoad = function() {
		if (lazyLoadWidgetRequest !== null || hasLazyLoadedWidgets) {
			return;
		}

		popupsLoader();
		var form = $('.js-sticky-change-element.js-booking-widget:first')

		lazyLoadWidgetRequest = sendRequest({
			data: form.serialize(),
			success: function(response) {
				try {
					renderWidgets(response);
				}
				catch (e) {
					if (console !== 'undefined') {
						console.log('Error: Could not parseJSON for booking widget: booking-widget.js');
					}
					confirm('Error', 'Sorry, there was a problem finding availability. Please try again or call our sales team on ' + response.SupportTelephone + '.', '', true);
				}
				hasLazyLoadedWidgets = true;
			},
			complete: function() {
				$(".js-booking-widget-done").removeClass("js-booking-widget-done");
				popupsLoader(true);
				lazyLoadWidgetRequest = null;
			}
		});
		return false;
	}

	// send a request to the booking-widget endpoint
	var sendRequest = function(request) {
		return $.ajax({
			url: '/api/booking-widget-view',
			data: request.data,
			success: request.success,
			error: request.error,
			complete: request.complete
		});
	}

	adRegManagerInit();
	$('#takeover-tab').off('click.booking-widget').on('click.booking-widget', onFirstLoad);
	$('.js-booking-widget:not(.js-booking-widget-done)').each(function() { bindBookingWidget($(this)) });
}


const renderWidgets = function(response) {
	const $response = $(response);

	const main = '#booking-widget-main';
	const groups = '#booking-widget-multiples';
	const flights = '#booking-widget-selected-flights'
	const flightPropertyId = '#js-flight-properties';

	const mainWidget = $response.find(main);
	const groupWidgets = $response.find(groups);
	const selectedFlights = $response.find(flights);

	$('#js-main-booking').empty().html(mainWidget.html());

	if (groupWidgets) {
		$('#js-multiples-booking .m-grid__inner').html(groupWidgets.html());
	}

	const showDetails = $('.c-flights-properties__container--active').length > 0;

	$(flightPropertyId).css("display", "none");
	if (selectedFlights.html()) {
		$(flightPropertyId).empty()
		$(flightPropertyId).css("display", "block");
		$(flightPropertyId).html(selectedFlights.html());
		if (showDetails) {
			toggleFlightDetails()
		}
		flightDetailsInit();
	}

	window.updateDynamicPhoneNumber()
}


export default function() {

	bookingWidgetFactory();

	window.site.doc.ajaxComplete(function(event, xhr, settings) {
		bookingWidgetFactory();

		if (settings && settings.url && settings.url.match('api/booking-widget-view')) {
			$('.js-folks-selector-wrapper').each(function() { showMessaging($(this)); });
		}
	});
}

